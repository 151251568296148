@if (label()) {
  <div class="categories-label-wrapper">
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="before"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 12V0H0C6.62742 0 12 5.37258 12 12Z"
        fill="#ffffff"
      />
    </svg>

    <div class="category-label">
      @if (isGrainWaste()) {
        <div class="recycling-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M12.5996 7.10194L9.69512 2.07085C9.40103 1.56154 8.66224 1.57244 8.38317 2.09038L6.39252 5.78736M9.39728 14.8016H15.5645C16.1527 14.8016 16.5125 14.1571 16.2046 13.6568L13.9044 9.9189M4.89014 8.41652L1.80012 13.6696C1.50566 14.1703 1.8666 14.8016 2.44765 14.8016H6.39252"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.8995 13.3012L9.39713 14.8036L10.8995 16.306M10.5239 6.61566L12.5762 7.16591L13.126 5.11328M2.82422 8.96839L4.87647 8.41851L5.42634 10.4708"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      } @else {
        <app-sprout-icon stroke="#ffffff"></app-sprout-icon>
      }
      <div class="name">{{ label() }}</div>
    </div>

    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="after"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 12V0H0C6.62742 0 12 5.37258 12 12Z"
        fill="#ffffff"
      />
    </svg>
  </div>
}
