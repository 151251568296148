import { Component, input } from '@angular/core';

import { SproutIconComponent } from '@components/ui/icons/sprout-icon.component';

@Component({
  selector: 'app-category-label',
  standalone: true,
  imports: [SproutIconComponent],
  templateUrl: './category-label.component.html',
  styleUrl: './category-label.component.scss',
})
export class CategoryLabelComponent {
  label = input.required<string | undefined | null>();
  isGrainWaste = input<boolean>(false);
}
