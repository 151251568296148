import { Pipe, PipeTransform } from '@angular/core';
import { declensionWord } from '@utils/declension-words';

@Pipe({
  name: 'declense',
  standalone: true,
})
export class DeclensePipe implements PipeTransform {
  transform(amount: number, expressions: string[]): string {
    return declensionWord({ expressions, amount });
  }
}
