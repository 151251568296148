<div class="announcement-card">
  <div class="image-wrapper">
    <a
      [routerLink]="[
        '/',
        ROUTE_ANNOUNCEMENT,
        announcement().category.code,
        announcement().slug,
      ]"
    >
      <img
        [src]="announcement().main_image.url"
        alt="Фотография"
        class="main-photo"
      />
    </a>
    <app-class-label
      [classification]="announcement().classification"
      class="class"
    ></app-class-label>
    <app-category-label
      [isGrainWaste]="announcement().category.code === 'grain_waste'"
      [label]="
        announcement().cereal !== null
          ? announcement().cereal?.name
          : announcement().category.label
      "
      class="category"
    ></app-category-label>
    <app-icon-button
      kind="favorite"
      variety="favorite"
      [smallSize]="true"
      class="favorite"
    ></app-icon-button>
  </div>
  <div class="description-block">
    <div class="label date">
      {{ announcement().created_at | date: "dd.MM.yyyy HH:mm" }}
    </div>
    <div class="price">
      @if (announcement().is_contract_price) {
        Цена договорная
      } @else {
        {{ announcement().price | number: "1.0-2" : "ru-Ru" }} руб. / т

        <sup>{{ announcement().is_with_vat ? "С НДС" : "Без НДС" }}</sup>
      }
    </div>

    @if (announcement().name) {
      <div class="title">{{ announcement().name }}</div>
    } @else {
      <div class="title">{{ announcement() | announcementName }}</div>
    }

    <div class="label">
      Объём: {{ announcement().weight | number: "1.0-2" : "ru-Ru" }} т
    </div>
    <div class="label">
      {{ announcement().type.value === "sell" ? "Продажа" : "Покупка" }}
    </div>
    <div class="label">
      <span>{{ announcement().region.name }},</span>
      {{ announcement().address }}
    </div>
  </div>
</div>
