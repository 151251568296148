import { HttpResponse } from '@angular/common/http';

import { TListResponse } from '@models/commonTypes';

export function getPaginationData<T>(
  response: HttpResponse<T>,
): TListResponse<T> {
  return {
    currentPage: Number(response.headers.get('X-Pagination-Current-Page')),
    totalPage: Number(response.headers.get('X-Pagination-Page-Count')),
    perPage: Number(response.headers.get('X-Pagination-Per-Page')),
    totalCount: Number(response.headers.get('X-Pagination-Total-Count')),
    data: response.body as T,
  };
}
