import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CategoryLabelComponent } from './category-label/category-label.component';
import { ClassLabelComponent } from './class-label/class-label.component';
import { IconButtonComponent } from '@components/ui/icon-button/icon-button.component';
import { TAnnouncementListData } from '../core/announcementsListTypes';
import { AnnouncementNamePipe } from '@pipes/announcement-name.pipe';
import { ROUTE_ANNOUNCEMENT } from '@settings/routes';

@Component({
  selector: 'app-announcement-card',
  standalone: true,
  imports: [
    ClassLabelComponent,
    CategoryLabelComponent,
    IconButtonComponent,
    CommonModule,
    AnnouncementNamePipe,
    RouterModule,
  ],
  templateUrl: './announcement-card.component.html',
  styleUrl: './announcement-card.component.scss',
})
export class AnnouncementCardComponent {
  protected readonly ROUTE_ANNOUNCEMENT = ROUTE_ANNOUNCEMENT;

  announcement = input.required<TAnnouncementListData>();
}
